<template>
  <div class="content">
    <a-form layout="inline">
      <a-form-item label="查询时间">
        <a-select
            v-model="query.time"
            style="width: 120px"
            @change="customQuery"
        >
          <a-select-option v-for="year in yearList" :key="year" :value="year">
            {{ year }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <iframe :src="src"
            style="width: 100%; height: 83vh; display: block; border: 0px;">
    </iframe>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {getToken} from "@/utils/auth";
import {formatToDate, getPreviewDate} from "@/utils/time";
import moment from "moment";
import {baseURL} from '@/api/network'

export default {
  name: "year",
  data() {
    return {
      query: {
        time: undefined,
      },
      raw: baseURL+'/jmreport/view/562033198581104640',
      src: '',
      yearList: [],
    }
  },
  created() {
    this.generateYearList()
    this.query.time = moment().year()
    this.generateSrc()
  },
  methods: {
    customQuery() {
      this.src = `${this.raw}&startTime=${formatToDate(moment(this.query.time, "YYYY").startOf('year'))}&endTime=${formatToDate(moment(this.query.time, "YYYY").startOf('year').add(1, 'years').subtract(1, 'days'))}`
    },
    generateSrc() {
      const token = getToken()
      const moduleToken = this.$store.getters.module.moduleCode
      this.raw = `${this.raw}?token=${token}&moduleToken=${moduleToken}`
      this.customQuery()
    },
    generateYearList() {
      const currentYear = moment().year()
      this.yearList = _.map(_.range(-50, 1), year => {
        return currentYear + year
      })
    }
  },
  computed: mapState({
    moduleCode: state => state.user.module.moduleCode
  }),
  watch: {
    moduleCode() {
      this.generateSrc()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>

